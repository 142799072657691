import { AdminGetProductsParams } from "@medusajs/medusa"
import { Response } from "@medusajs/medusa-js"
import { useQuery } from "react-query"
import { UseQueryOptionsWrapper } from "medusa-react/dist/types"
import Medusa from "../../services/api"
import { ResponsePromise } from "@medusajs/medusa-js/dist/typings"

export declare type AdminCategoryRes = Response<any> & {
  category: any
}

export const useAdminCategories = (
  query?: AdminGetProductsParams,
  options?: UseQueryOptionsWrapper<
    Response<{ data: { categories: any; count: number } }>,
    Error,
    ["admin-categories", "list"]
  >
) => {
  const { data, ...rest } = useQuery(
    ["admin-categories", ["list", query]],
    () => Medusa.categories.list({}) as ResponsePromise,
    options
  )

  return { ...data?.data, ...rest } as const
}

export const useAdminCategory = (
  id: string,
  options?: UseQueryOptionsWrapper<
    Response<AdminCategoryRes>,
    Error,
    ["admin-categories", typeof id]
  >
) => {
  const { data, ...rest } = useQuery(
    ["admin-categories", id],
    () => Medusa.categories.retrieve(id) as ResponsePromise<AdminCategoryRes>,
    options
  )

  return { ...data?.data, ...rest } as const
}
