import { AdminGetProductsParams } from "@medusajs/medusa"
import { Response } from "@medusajs/medusa-js"
import { useQuery } from "react-query"
import { UseQueryOptionsWrapper } from "medusa-react/dist/types"
import Medusa from "../../services/api"
import { ResponsePromise } from "@medusajs/medusa-js/dist/typings"

export declare type Brand = {
  name: string
  slug: string
}

export declare type AdminCategoryRes = Response<any> & {
  category: Brand
}

export declare type AdminBrandRes = Response<any> & {
  data: {
    brands: Brand[]
    count: number
  }
}

const adminQueryKey = "admin-brands"

export const useAdminBrands = (
  query?: AdminGetProductsParams,
  options?: UseQueryOptionsWrapper<
    Response<AdminBrandRes>,
    Error,
    [typeof adminQueryKey, "list"]
  >
) => {
  const { data, ...rest } = useQuery(
    [adminQueryKey, ["list", query]],
    () => Medusa.brands.list({}) as ResponsePromise,
    options
  )

  return { ...data?.data, ...rest } as const
}

export const useAdminBrand = (
  id: string,
  options?: UseQueryOptionsWrapper<
    Response<AdminCategoryRes>,
    Error,
    [typeof adminQueryKey, typeof id]
  >
) => {
  const { data, ...rest } = useQuery(
    [adminQueryKey, id],
    () => Medusa.brands.retrieve(id) as ResponsePromise<AdminCategoryRes>,
    options
  )

  return { ...data?.data, ...rest } as const
}
