import medusaRequest, { multipartRequest } from "./request"
import { Response } from "@medusajs/medusa-js"
import { ExtendedAdminProductResponse } from "../types/product"

const removeNullish = (obj) =>
  Object.entries(obj).reduce((a, [k, v]) => (v ? ((a[k] = v), a) : a), {})

const buildQueryFromObject = (search, prefix = "") =>
  Object.entries(search)
    .map(([key, value]) =>
      typeof value === "object"
        ? buildQueryFromObject(value, key)
        : `${prefix ? `${prefix}[${key}]` : `${key}`}=${value}`
    )
    .join("&")

const paths = {
  categories: "admin/categories",
  brands: "admin/brands",
}

export default {
  returnReasons: {
    retrieve(id) {
      const path = `/admin/return-reasons/${id}`
      return medusaRequest("GET", path)
    },
    list() {
      const path = `/admin/return-reasons`
      return medusaRequest("GET", path)
    },
    create(payload) {
      const path = `/admin/return-reasons`
      return medusaRequest("POST", path, payload)
    },
    update(id, payload) {
      const path = `/admin/return-reasons/${id}`
      return medusaRequest("POST", path, payload)
    },
    delete(id) {
      const path = `/admin/return-reasons/${id}`
      return medusaRequest("DELETE", path)
    },
  },
  apps: {
    authorize(data) {
      const path = `/admin/apps/authorizations`
      return medusaRequest("POST", path, data)
    },

    list() {
      const path = `/admin/apps`
      return medusaRequest("GET", path)
    },
  },
  auth: {
    session() {
      const path = `/admin/auth`
      return medusaRequest("GET", path)
    },
    authenticate(details) {
      const path = `/admin/auth`
      return medusaRequest("POST", path, details)
    },
    deauthenticate() {
      const path = `/admin/auth`
      return medusaRequest("DELETE", path)
    },
  },
  notifications: {
    list(search = {}) {
      const params = Object.keys(search)
        .map((k) => `${k}=${search[k]}`)
        .join("&")
      const path = `/admin/notifications${params && `?${params}`}`
      return medusaRequest("GET", path)
    },
    resend(id, config) {
      const path = `/admin/notifications/${id}/resend`
      return medusaRequest("POST", path, config)
    },
  },
  notes: {
    listByResource(resourceId) {
      const path = `/admin/notes?resource_id=${resourceId}`
      return medusaRequest("GET", path)
    },
    async create(resourceId, resourceType, value) {
      const path = `/admin/notes/`
      return medusaRequest("POST", path, {
        resource_id: resourceId,
        resource_type: resourceType,
        value,
      })
    },
    update(id, value) {
      const path = `admin/notes/${id}`
      return medusaRequest("POST", path, { value })
    },
    delete(id) {
      const path = `admin/notes/${id}`
      return medusaRequest("DELETE", path)
    },
  },
  customers: {
    retrieve(customerId) {
      const path = `/admin/customers/${customerId}`
      return medusaRequest("GET", path)
    },
    list(search = "") {
      const path = `/admin/customers${search}`
      return medusaRequest("GET", path)
    },
    update(customerId, update) {
      const path = `admin/customers/${customerId}`
      return medusaRequest("POST", path, update)
    },
  },
  store: {
    retrieve() {
      const path = `/admin/store`
      return medusaRequest("GET", path)
    },

    update(update) {
      const path = `/admin/store`
      return medusaRequest("POST", path, update)
    },

    addCurrency(code) {
      const path = `/admin/store/currencies/${code}`
      return medusaRequest("POST", path)
    },

    removeCurrency(code) {
      const path = `/admin/store/currencies/${code}`
      return medusaRequest("DELETE", path)
    },

    listPaymentProviders() {
      const path = `/admin/store/payment-providers`
      return medusaRequest("GET", path)
    },
  },
  shippingProfiles: {
    list() {
      const path = `/admin/shipping-profiles`
      return medusaRequest("GET", path)
    },

    create(data) {
      const path = `/admin/shipping-profiles`
      return medusaRequest("POST", path, data)
    },

    retrieve(profileId) {
      const path = `/admin/shipping-profiles/${profileId}`
      return medusaRequest("GET", path)
    },

    update(profileId, update) {
      const path = `/admin/shipping-profiles/${profileId}`
      return medusaRequest("POST", path, update)
    },
  },
  giftCards: {
    create(giftCard) {
      const path = `/admin/gift-cards`
      return medusaRequest("POST", path, giftCard)
    },

    retrieve(giftCardId) {
      const path = `/admin/gift-cards/${giftCardId}`
      return medusaRequest("GET", path)
    },

    list(search = {}) {
      const params = Object.keys(search)
        .map((k) => `${k}=${search[k]}`)
        .join("&")
      const path = `/admin/gift-cards${params && `?${params}`}`
      return medusaRequest("GET", path)
    },

    update(giftCardId, update) {
      const path = `/admin/gift-cards/${giftCardId}`
      return medusaRequest("POST", path, update)
    },

    delete(giftCardId) {
      const path = `/admin/gift-cards/${giftCardId}`
      return medusaRequest("DELETE", path)
    },
  },
  variants: {
    list(search = {}) {
      const params = Object.keys(search)
        .map((k) => `${k}=${search[k]}`)
        .join("&")
      const path = `/admin/variants${params && `?${params}`}`
      return medusaRequest("GET", path)
    },
  },
  products: {
    create(product) {
      const path = `/admin/products`
      return medusaRequest("POST", path, product)
    },

    retrieve(productId, query) {
      const params = Object.keys(query)
        .map((k) => `${k}=${query[k]}`)
        .join("&")
      const path = `/admin/products/${productId}${params && `?${params}`}`
      return medusaRequest("GET", path)
    },

    update(productId, update) {
      const path = `/admin/products/${productId}`
      return (medusaRequest("POST", path, update) as unknown) as Promise<
        Response<ExtendedAdminProductResponse>
      >
    },

    delete(productId) {
      const path = `/admin/products/${productId}`
      return medusaRequest("DELETE", path)
    },

    list(search = {}) {
      const params = Object.keys(search)
        .map((k) => `${k}=${search[k]}`)
        .join("&")
      const path = `/admin/products${params && `?${params}`}`
      return medusaRequest("GET", path)
    },

    listTypes() {
      const path = `/admin/products/types`
      return medusaRequest("GET", path)
    },

    listTagsByUsage() {
      const path = `/admin/products/tag-usage`
      return medusaRequest("GET", path)
    },

    variants: {
      create(productId, variant) {
        const path = `/admin/products/${productId}/variants`
        return medusaRequest("POST", path, variant)
      },

      retrieve(productId, variantId) {
        const path = `/admin/products/${productId}/variants/${variantId}`
        return medusaRequest("GET", path)
      },

      update(productId, variantId, update) {
        const path = `/admin/products/${productId}/variants/${variantId}`
        return medusaRequest("POST", path, update)
      },

      delete(productId, variantId) {
        const path = `/admin/products/${productId}/variants/${variantId}`
        return medusaRequest("DELETE", path)
      },

      list(productId) {
        const path = `/admin/products/${productId}/variants`
        return medusaRequest("GET", path)
      },
    },
    options: {
      create(productId, option) {
        const path = `/admin/products/${productId}/options`
        return medusaRequest("POST", path, option)
      },

      delete(productId, optionId) {
        const path = `/admin/products/${productId}/options/${optionId}`
        return medusaRequest("DELETE", path)
      },

      update(productId, optionId, update) {
        const path = `/admin/products/${productId}/options/${optionId}`
        return medusaRequest("POST", path, update)
      },
    },
  },
  categories: {
    create(product) {
      const path = `${paths.categories}`
      return medusaRequest("POST", path, product)
    },

    retrieve(categoryId) {
      const path = `${paths.categories}/${categoryId}`
      return medusaRequest("GET", path)
    },

    update(productId, update) {
      const path = `${paths.categories}/${productId}`
      return medusaRequest("POST", path, update)
    },

    delete(categoryId) {
      const path = `${paths.categories}/${categoryId}`
      return medusaRequest("DELETE", path)
    },

    list(search = {}) {
      const params = Object.keys(search)
        .map((k) => `${k}=${search[k]}`)
        .join("&")
      const path = `${paths.categories}${params && `?${params}`}`
      return medusaRequest("GET", path)
    },
  },
  brands: {
    create(data) {
      const path = `${paths.brands}`
      return medusaRequest("POST", path, data)
    },

    retrieve(id) {
      const path = `${paths.brands}/${id}`
      return medusaRequest("GET", path)
    },

    update(id, update) {
      const path = `${paths.brands}/${id}`
      return medusaRequest("POST", path, update)
    },

    delete(id) {
      const path = `${paths.brands}/${id}`
      return medusaRequest("DELETE", path)
    },

    list(search = {}) {
      const params = Object.keys(search)
        .map((k) => `${k}=${search[k]}`)
        .join("&")
      const path = `${paths.brands}${params && `?${params}`}`
      return medusaRequest("GET", path)
    },
  },
  uploads: {
    create(files) {
      const formData = new FormData()
      for (const f of files) {
        formData.append("files", f)
      }

      return multipartRequest("/admin/uploads", formData)
    },
  },
}
