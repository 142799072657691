import React from "react"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { trimValues } from "../../../../utils/trim-values"

const defaultCategory = {
  id: "",
  name: "",
  parent_id: null,
}

const CategoryFormContext = React.createContext<{
  onSubmit: (values: any) => void
  resetForm: () => void
  additionalDirtyState: Record<string, boolean>
} | null>(null)

export const CategoryFormProvider = ({
  category = defaultCategory,
  onSubmit,
  children,
}) => {
  const methods = useForm()

  const resetForm = () => {
    methods.reset({
      ...category,
    })
  }

  React.useEffect(() => {
    resetForm()
  }, [category])

  const handleSubmit = (values) => {
    const data = {
      ...trimValues(values),
    }

    onSubmit(data)
  }

  return (
    <FormProvider {...methods}>
      <CategoryFormContext.Provider
        value={{
          onSubmit: handleSubmit,
          resetForm,
          additionalDirtyState: {},
        }}
      >
        {children}
      </CategoryFormContext.Provider>
    </FormProvider>
  )
}

export const useCategoryForm = () => {
  const context = React.useContext(CategoryFormContext)
  const form = useFormContext()
  if (!context) {
    throw new Error("useCategoryForm must be a child of CategoryFormContext")
  }
  return { ...form, ...context }
}
