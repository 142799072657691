import { Response } from "@medusajs/medusa-js"
import { useMutation, UseMutationOptions, useQueryClient } from "react-query"
import Medusa from "../../services/api"
import { buildOptions } from "medusa-react/src/hooks/utils/buildOptions"

export const useAdminCreateBrand = (
  options?: UseMutationOptions<Response<any>, Error>
) => {
  const queryClient = useQueryClient()

  return useMutation(
    (payload) => Medusa.brands.create(payload),
    buildOptions(queryClient, ["admin-brands", ["list"]], options)
  )
}

export const useAdminUpdateBrand = (id: string, options?: any) => {
  const queryClient = useQueryClient()

  return useMutation(
    (payload) => Medusa.brands.update(id, payload),
    buildOptions(queryClient, ["admin-brands", [id, "list"]], options)
  )
}

export const useAdminDeleteBrand = (
  id: string,
  options?: UseMutationOptions<
    Response<{ id: string; object: "product"; deleted: boolean }>,
    Error,
    void
  >
) => {
  const queryClient = useQueryClient()

  return useMutation(
    () => Medusa.brands.delete(id),
    buildOptions(queryClient, ["admin-brands", ["list"]], options)
  )
}
//
// export const useAdminCreateVariant = (
//   productId: string,
//   options?: UseMutationOptions<
//     Response<AdminProductsRes>,
//     Error,
//     AdminPostProductsProductVariantsReq
//   >
// ) => {
//   const { client } = useMedusa()
//   const queryClient = useQueryClient()
//
//   return useMutation(
//     (payload: AdminPostProductsProductVariantsReq) =>
//       client.admin.products.createVariant(productId, payload),
//     buildOptions(
//       queryClient,
//       [adminCategoriesKeys.lists(), adminCategoriesKeys.detail(productId)],
//       options
//     )
//   )
// }
//
// export const useAdminUpdateVariant = (
//   productId: string,
//   options?: UseMutationOptions<
//     Response<AdminProductsRes>,
//     Error,
//     AdminPostProductsProductVariantsReq & { variant_id: string }
//   >
// ) => {
//   const { client } = useMedusa()
//   const queryClient = useQueryClient()
//
//   return useMutation(
//     ({
//       variant_id,
//       ...payload
//     }: AdminPostProductsProductVariantsReq & { variant_id: string }) =>
//       client.admin.products.updateVariant(productId, variant_id, payload),
//     buildOptions(
//       queryClient,
//       [adminCategoriesKeys.lists(), adminCategoriesKeys.detail(productId)],
//       options
//     )
//   )
// }
//
// export const useAdminDeleteVariant = (
//   productId: string,
//   options?: UseMutationOptions<
//     Response<AdminProductsDeleteVariantRes>,
//     Error,
//     string
//   >
// ) => {
//   const { client } = useMedusa()
//   const queryClient = useQueryClient()
//
//   return useMutation(
//     (variantId: string) =>
//       client.admin.products.deleteVariant(productId, variantId),
//     buildOptions(
//       queryClient,
//       [adminCategoriesKeys.lists(), adminCategoriesKeys.detail(productId)],
//       options
//     )
//   )
// }
//
// export const useAdminCreateProductOption = (
//   productId: string,
//   options?: UseMutationOptions<
//     Response<AdminProductsRes>,
//     Error,
//     AdminPostProductsProductOptionsReq
//   >
// ) => {
//   const { client } = useMedusa()
//   const queryClient = useQueryClient()
//
//   return useMutation(
//     (payload: AdminPostProductsProductOptionsReq) =>
//       client.admin.products.addOption(productId, payload),
//     buildOptions(queryClient, adminCategoriesKeys.detail(productId), options)
//   )
// }
//
// export const useAdminUpdateProductOption = (
//   productId: string,
//   options?: UseMutationOptions<
//     Response<AdminProductsRes>,
//     Error,
//     AdminPostProductsProductOptionsOption & { option_id: string }
//   >
// ) => {
//   const { client } = useMedusa()
//   const queryClient = useQueryClient()
//
//   return useMutation(
//     ({
//       option_id,
//       ...payload
//     }: AdminPostProductsProductOptionsOption & { option_id: string }) =>
//       client.admin.products.updateOption(productId, option_id, payload),
//     buildOptions(queryClient, adminCategoriesKeys.detail(productId), options)
//   )
// }
//
// export const useAdminDeleteProductOption = (
//   productId: string,
//   options?: UseMutationOptions<
//     Response<AdminProductsDeleteOptionRes>,
//     Error,
//     string
//   >
// ) => {
//   const { client } = useMedusa()
//   const queryClient = useQueryClient()
//
//   return useMutation(
//     (optionId: string) =>
//       client.admin.products.deleteOption(productId, optionId),
//     buildOptions(queryClient, adminCategoriesKeys.detail(productId), options)
//   )
// }
