import * as React from "react"
import Breadcrumb from "../../../components/molecules/breadcrumb"
import RawJSON from "../../../components/organisms/raw-json"
import General from "./sections/general"

type CategoryFormProps = {
  category?: any
  isEdit?: boolean
}

const CategoryForm = ({ category, isEdit = false }: CategoryFormProps) => {
  return (
    <div>
      <Breadcrumb
        currentPage={"Category Details"}
        previousBreadcrumb={"Categories"}
        previousRoute="/a/categories"
      />
      <div className="flex flex-col space-y-base pb-2xlarge">
        <General isEdit={isEdit} category={category} />
        <RawJSON data={category} title="Raw category" />
      </div>
    </div>
  )
}

export default CategoryForm
