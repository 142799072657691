import { useParams } from "@reach/router"
import { navigate } from "gatsby"
import React from "react"
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon"
import Input from "../../../../components/molecules/input"
import BodyCard from "../../../../components/organisms/body-card"
import useImperativeDialog from "../../../../hooks/use-imperative-dialog"
import useNotification from "../../../../hooks/use-notification"
import { getErrorMessage } from "../../../../utils/error-messages"
import { useBrandForm } from "../form/brand-form-context"
import { useAdminDeleteCategory } from "../../../../hooks/categories"

const General = ({ isEdit = false, entity }) => {
  const { register } = useBrandForm()

  return (
    <GeneralBodyCard
      isEdit={isEdit}
      entity={entity}
      title="General"
      subtitle="Create a new brand"
    >
      <div className="mt-large">
        <h6 className="inter-base-semibold mb-1">Details</h6>
        <div className="flex gap-8 mb-base">
          <Input
            id="name"
            label="Name"
            name="name"
            placeholder="Shimano, Daiwa..."
            required
            ref={register({
              required: "Name is required",
              minLength: 1,
              pattern: /(.|\s)*\S(.|\s)*/,
            })}
          />
        </div>
        <div className="flex gap-8 mb-base">
          <Input
            id="name"
            label="Slug"
            name="slug"
            placeholder="shimano, hook-eye..."
            required
            ref={register({
              required: "Slug is required",
              minLength: 1,
              pattern: /(.|\s)*\S(.|\s)*/,
            })}
          />
        </div>
      </div>
    </GeneralBodyCard>
  )
}

const GeneralBodyCard = ({ isEdit, entity, ...props }) => {
  const params = useParams()
  const dialog = useImperativeDialog()
  const notification = useNotification()
  const deleteProduct = useAdminDeleteCategory(params?.id)

  const onDelete = async () => {
    const shouldDelete = await dialog({
      heading: "Delete Brand",
      text: "Are you sure you want to delete this brand",
    })
    if (shouldDelete) {
      deleteProduct.mutate(undefined, {
        onSuccess: () => {
          notification("Success", "Brand deleted successfully", "success")
          navigate("/a/categories/")
        },
        onError: (err) => {
          notification("Ooops", getErrorMessage(err), "error")
        },
      })
    }
  }

  const actionables = [
    {
      label: "Delete Brand",
      onClick: onDelete,
      variant: "danger" as const,
      icon: <TrashIcon />,
    },
  ]

  return (
    <BodyCard
      actionables={isEdit ? actionables : undefined}
      forceDropdown
      {...props}
    />
  )
}

export default General
