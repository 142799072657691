export const brandToFormValuesMapper = (brand) => {
  return {
    ...brand,
  }
}

export const formValuesToCreateBrandMapper = (values) => {
  return {
    ...values,
  }
}

export const formValuesToUpdateBrandMapper = (values) => {
  return {
    ...values,
  }
}
