import { navigate } from "gatsby"
import { useAdminDeleteProduct, useAdminUpdateProduct } from "medusa-react"
import * as React from "react"
import useImperativeDialog from "../../../hooks/use-imperative-dialog"
import useNotification from "../../../hooks/use-notification"
import { getErrorMessage } from "../../../utils/error-messages"
import DuplicateIcon from "../../fundamentals/icons/duplicate-icon"
import EditIcon from "../../fundamentals/icons/edit-icon"
import PublishIcon from "../../fundamentals/icons/publish-icon"
import TrashIcon from "../../fundamentals/icons/trash-icon"
import UnpublishIcon from "../../fundamentals/icons/unpublish-icon"
import { ActionType } from "../../molecules/actionables"
import useCopyProduct from "./use-copy-product"
import { useAdminDeleteCategory } from "../../../hooks/categories"

const useCategoryActions = (category) => {
  const notification = useNotification()
  const dialog = useImperativeDialog()
  const deleteCategory = useAdminDeleteCategory(category?.id)

  const handleDelete = async () => {
    const shouldDelete = await dialog({
      heading: "Delete Category",
      text: "Are you sure you want to delete this category?",
    })

    if (shouldDelete) {
      deleteCategory.mutate()
    }
  }

  const getActions = (): ActionType[] => [
    {
      label: "Edit",
      onClick: () => navigate(`/a/categories/${category.id}`),
      icon: <EditIcon size={20} />,
    },
    // {
    //   label: "Duplicate",
    //   onClick: () => copyProduct(product),
    //   icon: <DuplicateIcon size={20} />,
    // },
    {
      label: "Delete",
      variant: "danger",
      onClick: handleDelete,
      icon: <TrashIcon size={20} />,
    },
  ]

  return {
    getActions,
  }
}

export default useCategoryActions
