import * as React from "react"
import Breadcrumb from "../../../components/molecules/breadcrumb"
import RawJSON from "../../../components/organisms/raw-json"
import General from "./sections/general"

type BrandFormProps = {
  brand?: any
  isEdit?: boolean
}

const BrandForm = ({ brand, isEdit = false }: BrandFormProps) => {
  return (
    <div>
      <Breadcrumb
        currentPage={"Category Details"}
        previousBreadcrumb={"Categories"}
        previousRoute="/a/categories"
      />
      <div className="flex flex-col space-y-base pb-2xlarge">
        <General isEdit={isEdit} entity={brand} />
        <RawJSON data={brand} title="Raw category" />
      </div>
    </div>
  )
}

export default BrandForm
