import React, { useEffect, useState } from "react"
import { FieldValues } from "react-hook-form"
import toast from "react-hot-toast"
import Spinner from "../../components/atoms/spinner"
import Toaster from "../../components/declarative-toaster"
import FormToasterContainer from "../../components/molecules/form-toaster"
import useNotification from "../../hooks/use-notification"
import { getErrorMessage } from "../../utils/error-messages"
import { checkForDirtyState } from "../../utils/form-helpers"
import { handleFormError } from "../../utils/handle-form-error"
import {
  categoryToFormValuesMapper,
  formValuesToCreateCategoryMapper,
  formValuesToUpdateCategoryMapper,
} from "./category-form/form/mappers"
import {
  useAdminCategory,
  useAdminCreateCategory,
  useAdminUpdateCategory,
} from "../../hooks/categories"
import {
  CategoryFormProvider,
  useCategoryForm,
} from "./category-form/form/category-form-context"
import CategoryForm from "./category-form"
import { navigate } from "gatsby"

const CreateCategoryPage = () => {
  const notification = useNotification()

  const createProduct = useAdminCreateCategory()
  const [submitting, setSubmitting] = useState(false)

  const onSubmit = async (data) => {
    setSubmitting(true)

    const newData = {
      ...data,
    }

    createProduct.mutate(formValuesToCreateCategoryMapper(newData), {
      onSuccess: () => {
        setSubmitting(false)
        navigate(`/a/categories`)
        notification("Success", "Category was successfully created", "success")
      },
      onError: (error) => {
        setSubmitting(false)
        notification("Error", getErrorMessage(error), "error")
      },
    })
  }

  return (
    <CategoryFormProvider onSubmit={onSubmit}>
      <CategoryForm />
      <UpdateNotification isLoading={submitting} />
    </CategoryFormProvider>
  )
}

const TOAST_ID = "edit-category-dirty"

const UpdateNotification = ({ isLoading = false }) => {
  const {
    formState,
    onSubmit,
    handleSubmit,
    resetForm,
    additionalDirtyState,
  } = useCategoryForm()
  const [visible, setVisible] = useState(false)
  const [blocking, setBlocking] = useState(true)

  const onUpdate = (values: FieldValues) => {
    onSubmit({ ...values })
  }

  useEffect(() => {
    const timeout = setTimeout(setBlocking, 300, false)
    return () => clearTimeout(timeout)
  }, [])

  const isDirty = checkForDirtyState(
    formState.dirtyFields,
    additionalDirtyState
  )

  useEffect(() => {
    if (!blocking) {
      setVisible(isDirty)
    }

    return () => {
      toast.dismiss(TOAST_ID)
    }
  }, [isDirty])

  return (
    <Toaster
      visible={visible}
      duration={Infinity}
      id={TOAST_ID}
      position="bottom-right"
    >
      <FormToasterContainer isLoading={isLoading}>
        <FormToasterContainer.Actions>
          <FormToasterContainer.ActionButton
            onClick={handleSubmit(onUpdate, handleFormError)}
          >
            Save
          </FormToasterContainer.ActionButton>
          <FormToasterContainer.DiscardButton onClick={resetForm}>
            Discard
          </FormToasterContainer.DiscardButton>
        </FormToasterContainer.Actions>
      </FormToasterContainer>
    </Toaster>
  )
}

export default CreateCategoryPage
