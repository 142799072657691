export const categoryToFormValuesMapper = (category) => {
  return {
    ...category,
  }
}

export const formValuesToCreateCategoryMapper = (values) => {
  return {
    ...values,
  }
}

export const formValuesToUpdateCategoryMapper = (values) => {
  return {
    ...values,
  }
}
