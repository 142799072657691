import { useParams } from "@reach/router"
import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import TrashIcon from "../../../../components/fundamentals/icons/trash-icon"
import Input from "../../../../components/molecules/input"
import BodyCard from "../../../../components/organisms/body-card"
import useImperativeDialog from "../../../../hooks/use-imperative-dialog"
import useNotification from "../../../../hooks/use-notification"
import { getErrorMessage } from "../../../../utils/error-messages"
import { useCategoryForm } from "../form/category-form-context"
import { useAdminDeleteCategory } from "../../../../hooks/categories"
import Select from "../../../../components/molecules/select"
import Medusa from "../../../../services/api"
import { Controller } from "react-hook-form"

const General = ({ isEdit = false, category }) => {
  const { control, register, setValue } = useCategoryForm()

  const debouncedFetch = (filter) => {
    return Medusa.categories
      .list({
        q: filter,
        offset: 0,
        limit: 10,
      })
      .then(({ data }) => {
        const categories = data.categories.map(({ id, name }) => ({
          label: name,
          value: id,
        }))

        categories.unshift({ label: "No category", value: null })

        return categories
      })
      .catch((error) => [])
  }

  const [selectedParent, setSelectedParent] = useState({
    label: category?.parent?.name,
    value: category?.parent?.id,
  })

  useEffect(() => {}, [])
  const selectParent = (val) => {
    setSelectedParent(val)
    setValue("parent_id", val.value)
  }

  return (
    <GeneralBodyCard
      isEdit={isEdit}
      category={category}
      title="General"
      subtitle="Create a new category"
    >
      <div className="mt-large">
        <h6 className="inter-base-semibold mb-1">Details</h6>
        <label
          htmlFor="name"
          className="inter-small-regular text-grey-50 block max-w-[370px] mb-base"
        >
          Give your product a short and clear name. 50-60 characters is the
          recommended length for search engines.
        </label>
        <div className="flex gap-8 mb-base">
          <Input
            id="name"
            label="Name"
            name="name"
            placeholder="Rods, Reels..."
            required
            ref={register({
              required: "Name is required",
              minLength: 1,
              pattern: /(.|\s)*\S(.|\s)*/,
            })}
          />
        </div>
        <div className="flex gap-8 mb-base">
          <Controller
            control={control}
            name="parent_id"
            render={({ onChange }) => {
              return (
                <Select
                  fullWidth
                  label="Find category"
                  value={selectedParent}
                  options={[]}
                  enableSearch
                  onChange={(e) => {
                    onChange()
                    selectParent(e)
                  }}
                  filterOptions={debouncedFetch}
                />
              )
            }}
          />
        </div>
      </div>
    </GeneralBodyCard>
  )
}

const GeneralBodyCard = ({ isEdit, category, ...props }) => {
  const params = useParams()
  const dialog = useImperativeDialog()
  const notification = useNotification()
  const deleteProduct = useAdminDeleteCategory(params?.id)

  const onDelete = async () => {
    const shouldDelete = await dialog({
      heading: "Delete Category",
      text: "Are you sure you want to delete this category",
    })
    if (shouldDelete) {
      deleteProduct.mutate(undefined, {
        onSuccess: () => {
          notification("Success", "Category deleted successfully", "success")
          navigate("/a/categories/")
        },
        onError: (err) => {
          notification("Ooops", getErrorMessage(err), "error")
        },
      })
    }
  }

  const actionables = [
    {
      label: "Delete Category",
      onClick: onDelete,
      variant: "danger" as const,
      icon: <TrashIcon />,
    },
  ]

  return (
    <BodyCard
      actionables={isEdit ? actionables : undefined}
      forceDropdown
      {...props}
    />
  )
}

export default General
