import BodyCard from "../../components/organisms/body-card"
import TableViewHeader from "../../components/organisms/custom-table-header"
import React from "react"
import Button from "../../components/fundamentals/button"
import { navigate } from "gatsby"
import PlusIcon from "../../components/fundamentals/icons/plus-icon"
import CategoriesTable from "../../components/templates/categories-table"
import { RouteComponentProps, Router } from "@reach/router"
import EditCategoryPage from "./edit"
import CreateCategoryPage from "./new"

const CategoryIndex: React.FC<RouteComponentProps> = () => {
  const CurrentAction = () => {
    return (
      <div className="flex space-x-2">
        <Button
          variant="secondary"
          size="small"
          onClick={() => navigate(`/a/categories/new`)}
        >
          <PlusIcon size={20} />
          New Category
        </Button>
      </div>
    )
  }

  const CurrentView = () => {
    return <CategoriesTable />
  }

  return (
    <>
      <div className="flex flex-col grow h-full">
        <div className="w-full flex flex-col grow">
          <BodyCard
            forceDropdown={false}
            customActionable={CurrentAction()}
            customHeader={
              <TableViewHeader
                views={["categories"]}
                setActiveView={() => null}
                activeView={"categories"}
              />
            }
          >
            <CurrentView />
          </BodyCard>
        </div>
      </div>
    </>
  )
}

const Categories = () => {
  return (
    <Router>
      <CategoryIndex path="/" />
      <EditCategoryPage path=":id" />
      <CreateCategoryPage path={"/new"} />
    </Router>
  )
}

export default Categories
